<template>
  <div style="display: contents">
    <BlockPageHeader
      :page="page"
      :users="users"
      :show-title="true"
      class="mb-5"
      @open="$emit('open', $event)"
      @edit="$emit('edit', page)"
    />
    <PanelPagesContentTranslator
      v-if="translator && currentVersion"
      :key="`${versionKey}translator${currentVersion.state}`"
      :ref="`editor_${versionKey}`"
      :page-key="pageKey"
      :page="page"
      :version-key="versionKey"
      :version="currentVersion"
      :original-key="originalKey"
      :validations="validations"
      :editable="editable"
      :users="users"
      :comments-document="comments['document']"
      :comments="comments"
      :active-block="activeBlock"
      class="main-editor min-h-full max-h-full"
      :class="{
        rtl: isRTL
      }"
      v-on="$listeners"
    />
    <PanelPagesContentEditor
      v-else-if="page && currentVersion && validations"
      :key="`${versionKey}editor${currentVersion.state}`"
      :ref="`editor_${versionKey}`"
      class="main-editor min-h-full max-h-full"
      :class="{
        rtl: isRTL
      }"
      wide
      paddingless
      borderless
      :page-key="pageKey"
      :page="page"
      :show-chapters="showChapters"
      :version="currentVersion"
      :validations="validations"
      :editable="editable"
      :users-editing="usersEditing"
      :users="users"
      :active-block="activeBlock"
      :comments-document="comments['document']"
      :comments="comments"
      v-on="$listeners"
    ></PanelPagesContentEditor>
  </div>
</template>

<script>
export default {
  components: {
    PanelPagesContentTranslator: () =>
      import('@/fw-modules/fw-core-vue/pages/components/panels/PanelPagesContentTranslator.vue'),
    PanelPagesContentEditor: () =>
      import('@/fw-modules/fw-core-vue/pages/components/panels/PanelPagesContentEditor.vue'),
    BlockPageHeader: () => import('@/fw-modules/fw-core-vue/pages/components/blocks/BlockPageHeader.vue')
  },
  props: {
    pageKey: {
      type: String,
      required: true
    },
    isRTL: {
      type: Boolean,
      default: false
    },
    showChapters: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      required: true
    },
    currentVersion: {
      type: Object,
      required: true
    },
    versionKey: {
      type: String,
      required: true
    },
    validations: {
      type: Object,
      required: true
    },
    originalKey: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    usersEditing: {
      type: Object,
      required: true
    },
    users: {
      type: Object,
      required: true
    },
    activeBlock: {
      type: Object,
      default: () => {}
    },
    comments: {
      type: Object,
      required: true
    },
    translator: {
      type: Boolean,
      default: false
    }
  }
}
</script>
